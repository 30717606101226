import request from '../utils/request'
import qs from 'qs'
export function getInfo(accountNo,code){
    return request({
        url: 'billSearch/getInfo.do',
        method: 'post',
        data: qs.stringify({accountNo: accountNo,code:code})
    })
}


export function getBillList(accountNo){
    return request({
        url: 'billSearch/getAccountBillList.do',
        method: 'post',
        data: qs.stringify({accountNo: accountNo})
    })
}

export function checkBillPay(accountNo,billNoSet){
    return request({
        url: 'billSearch/getBillSettlement.do',
        method: 'post',
        data:  qs.stringify({accountNo:accountNo,selBillArrayStr: billNoSet})
    })
}

export function getBillDetail(accountNo,billNo){
    return request({
        url: 'billSearch/getBillDetail.do',
        method: 'post',
        data:  qs.stringify({accountNo:accountNo,billNo: billNo})
    })
}

export function billPay(account,selBillArray,payAmount){
    return request({
        url: 'billSearch/payBill.do',
        method: 'post',
        data:  qs.stringify({account:account,selBillArray:selBillArray,payAmount:payAmount})
    })
}

export function payDemo(){
    return request({
        url: 'billSearch/payDemo.do',
        method: 'post',
        data: {'postType':'application/json'}
    })
}
