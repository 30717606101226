<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            title="快捷查交水费"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <div class="notice-content">
          <span style="color: #1989fa;">温馨提示：</span><br>&nbsp;&nbsp;&nbsp;&nbsp;请输入正确的用水户号查询欠费情况，根据欠费账单交纳水费。谢谢！
    </div>
    <div class="section-box">
      <!-- 查询界面 -->
      <van-form @submit="onSubmit" style="padding-top: 10px;padding-bottom: 10px; border-bottom:1px solid #ebebeb;padding-right: 5px;">
        <van-row>
          <van-col span="24">
            <van-field
                v-model="accountNo"
                name="accountNo"
                label=""
                label-width="0"
                placeholder="请输入户号查交费"
                border
                clearable
                :left-icon="require('../../assets/images/search.png')"
                :rules="[{ required: true, message: '' }]"
            />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-field
                v-model="code"
                center
                clearable
                label=""
                label-width="0"
                placeholder="请输入验证码"
                :left-icon="require('../../assets/images/code.png')"
                :rules="[{ required: true, message: '' }]"
                @focus="onCodeFocus"
            >
              <template #button>
                <van-image
                    width="80"
                    height="30"
                    @click="refreshCode"
                    :src="imageCodeUrl"
                />
              </template>
            </van-field>
          </van-col>
        </van-row>
        <van-row gutter="40">
          <van-col span="20" offset="2" style="padding-top:10px">
            <van-button type="info"  block size="small">查水费</van-button>
          </van-col>
        </van-row>
      </van-form>
      <!-- 查询界面结束 -->
      <van-collapse v-if="query" v-model="activeNames">
        <van-collapse-item title="户号信息" name="1" class="collapse-title">
          <van-row class="collapse-content">
           <div class="main-top" style="background:#fff">
            <div class="main-top-div1">户号：{{accountInfo.accountNo}}</div>
            <div class="main-top-div2">户名：{{accountInfo.accountName}}</div>
            <div class="main-top-div3">地址：{{accountInfo.accountAddress}}</div>
            </div>
          </van-row>
        </van-collapse-item>
        <van-collapse-item title="欠费信息" name="2" class="collapse-title">
          <van-empty v-if="!isArrears" description="当前户号不存在欠费信息" class="collapse-content" />
          <table class="bill-base-detail" v-if="isArrears">
            <tr>
              <th style="width:40px;">选择</th>
              <th style="text-align:left">账单月份</th>
              <th style="text-align:right;width:180px;">金额（元）</th>
            </tr>
            <tr v-for="(bill,index) in billList">
              <td><input type="checkbox" name="bill-item" :value="bill.账单编号+'|'+bill.合计金额+'|全部|'+bill.账单月份" v-model="selBillList"></td>
              <td style="text-align:left">{{bill.账单月份}}</td>
              <td style="text-align:right">{{bill.合计金额}}</td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" :checked="defaultChecked" @click="onSelectAllBill">
              </td>
              <td style="text-align:left;font-weight:600;">全选</td>
              <td style="text-align:right;font-weight:600;"><span style="padding-right:10px">合计（元）:</span>{{selBillTotalAmount}}</td>
            </tr>
            <tr>
              <td colspan="5" style="text-align:right;font-weight:600;border-bottom:0px;">
                <van-button type="info" hairline size="small" @click="toSettlement"  is-link>去结算</van-button>
              </td>
            </tr>
          </table>
        </van-collapse-item>
      </van-collapse>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getCookieKey} from "../../utils/cookie";
import {getInfo} from "../../api/billSearch";

export default {
  name: "convenient",
  components: {Loading},
  data(){
    return{
      defaultChecked:false,
      billList:[],
      selBillList:[],
      selBillNoSet:'',
      isShow : false,
      lodingTxt: '加载中...',
      accountNo: '',
      code:'',
      imageCodeUrl: process.env.VUE_APP_IMAGE_CODE_URL,
      activeNames: ['1','2'],
      query:false,
      accountInfo:{},
      isArrears:false,//是否存在欠费
    }
  },
  created() {
    const uuid = getCookieKey(process.env.VUE_APP_TOKEN_KEY)
    if (uuid){
      this.uuid = uuid
      this.imageCodeUrl = process.env.VUE_APP_IMAGE_CODE_URL+'?token='+uuid
    }
  },
  computed: {
        selBillTotalAmount: function () {
            let totalAmount=0.00;
            let billNoList=[];
            this.selBillList.forEach(selBill => {
              billNoList.push(selBill.split("|")[0]);
              let amountStr=selBill.split("|")[1];
              let billAmount=parseFloat(amountStr);
              totalAmount=totalAmount+billAmount;
            });
            this.selBillNoSet=billNoList.join(",");
            return totalAmount;
        }
  },
  methods:{
    onSubmit(){
      const that = this
      that.isShow = true
      that.query = false
      that.isArrears = false
      that.accountInfo = {}
      that.billList = []
      that.lodingTxt = '查询中...'
      that.defaultChecked=false
      that.refreshCode();
      getInfo(that.accountNo,that.code).then(response => {
        that.isShow = false
        that.code = ''
        //console.log("请求返回："+JSON.stringify(response));
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          if (response.data.accountInfo) {
            that.query = true
            that.accountInfo = response.data.accountInfo
            localStorage.setItem("selectedAccount",JSON.stringify(that.accountInfo));
          }
          else
            that.query = false
          that.billList = response.data.billInfo
          if (response.data.billInfo && response.data.billInfo.length > 0){
            that.isArrears = true
            that.onSelectAllBill();
          }
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.code = ''
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    toSettlement:function(){
      const that=this;
      if (that.selBillList.length==0){
        this.$toast('请选择需要交费的账单')
        return
      }
      let queryPar={}
      queryPar.billNoSet=that.selBillList;
      this.$router.push({path:'../payment/settlement',query:queryPar})
    },
    toDetail:function(billNo){
      const that=this;
      if (that.selBillList.length==0){
        this.$toast('请选择需要交费的账单')
        return
      }
      let queryPar={};
      queryPar.accountNo=that.accountInfo.accountNo;
      queryPar.address=that.accountInfo.accountAddress;
      queryPar.accountName=that.accountInfo.accountName;
      queryPar.billNo=billNo;
      this.$router.push({ path:'../payment/billDetail',query:queryPar})
    },
    onSelectAllBill(){
        //console.log("全选操作:");
        this.selBillList=[];
        if(this.defaultChecked){
          this.defaultChecked=false;
        }else{
          this.defaultChecked=true;
          this.billList.forEach(bill => {
            let billStr=bill.账单编号+'|'+bill.合计金额+'|全部|'+bill.账单月份;
            this.selBillList.push(billStr);
          });
        }
    },
    onBack : function (){
      this.$router.go(-1);
    },
    onCodeFocus(){
      //this.refreshCode()
    },
    /**
     * 验证码刷新
     */
    refreshCode(){
      this.imageCodeUrl = process.env.VUE_APP_IMAGE_CODE_URL +'?token='+this.uuid+'&rnd=' + Math.random()
    }
  }
}
</script>

<style scoped>
/deep/ .van-collapse-item__content{
  padding:0px!important;
}
.main-top{
  padding: 0px;
  border-bottom:0px solid #ebebeb;
  color:#323233;
  font-size:100%;
}

.main-top-div1{
  height:40px;
  padding:5px 10px 5px 10px;
  color:#323233;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div2{
  height:40px;
  padding:0px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div3{
  padding:5px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:20px;
  font-weight:normal;
}


.collapse-title{background: #cccccc;font-weight: bold;}
.collapse-content{font-weight: lighter;}
.bill-base-detail {
  width: 100%;
  font-size: 100%;
  color: #515151;
  border:0px solid #fff;
}

.bill-base-detail th {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color:#ffffff;
  background: #1989fa;
}

.bill-base-detail td {
  padding:5px 10px 4px 5px;
  border-bottom:1px solid #ddd;
  height: 40px;
  line-height: 30px;
  text-align: center;
  background: #fff;
}

.bill-base-detail td input{
  height:18px;
  width:18px;
  background-color: #1989fa!important;
}
.bill-base-detail td input:checked{;background-color: #1989fa;}
.notice-content-title{
  font-size:16px;
  line-height:30px;
  text-indent:25px;
  letter-spacing:1px;
  padding:0px;
}
.notice-content{
  font-size:16px;
  line-height:30px;
  text-indent:25px;
  letter-spacing:1px;
  padding:10px;
}
</style>
