<template>
  <van-overlay :show="isShow">
    <div class="logding-wrapper">
      <van-loading size="24px" vertical color="#ff5f16">{{ lodingTxt }}</van-loading>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "logding",
  props:{
    isShow: {
      type:Boolean
    },
    lodingTxt:{
      type: String
    }
  }
}
</script>

<style scoped>

</style>